import React from "react"

const IconTweetTooltipLeft = props => (
  <svg width="40" height="47" viewBox="0 0 40 47" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M27.0711 7L20.0711 0L13.0711 7H6C2.68629 7 0 9.68629 0 13V41C0 44.3137 2.68629 47 6 47H34C37.3137 47 40 44.3137 40 41V13C40 9.68629 37.3137 7 34 7H27.0711Z" fill="#292F33"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M32.62 19.37C31.71 19.77 30.74 20.04 29.72 20.17C30.76 19.55 31.56 18.55 31.94 17.38C30.96 17.96 29.88 18.38 28.73 18.61C27.81 17.61 26.5 17 25.05 17C22.26 17 20 19.26 20 22.05C20 22.45 20.04 22.83 20.13 23.2C15.93 22.99 12.21 20.98 9.72 17.92C9.28 18.67 9.03 19.54 9.03 20.46C9.03 22.21 9.92 23.76 11.28 24.66C10.45 24.64 9.67 24.41 8.99 24.03V24.09C8.99 26.54 10.73 28.58 13.04 29.04C12.62 29.15 12.17 29.22 11.71 29.22C11.38 29.22 11.07 29.19 10.76 29.13C11.4 31.14 13.27 32.6 15.48 32.64C13.75 33.99 11.57 34.8 9.2 34.8C8.79 34.8 8.39 34.78 8 34.73C10.24 36.16 12.89 37 15.74 37C25.03 37 30.11 29.3 30.11 22.63L30.1 21.98C31.09 21.27 31.95 20.38 32.62 19.37Z" fill="#FCFBF9"/>
  </svg>
)

export default IconTweetTooltipLeft
