import { isClientSide } from "../utils"

/*
  Browser / UX utils
*/

// Utility to open a Twitter share window
export const openTweetWindow = (textContent) => {
  if (isClientSide()) {
    const truncatedTextContent = textContent.length > 180 ? `${textContent.substring(0, 180)}...` : textContent
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(truncatedTextContent)}&via=helpscout`

    const width = 640
    const height = 300
    // Centering the popup (doesn't work in all browsers)
    const left = (window.innerWidth / 2) - (width / 2)
    const top = window.innerHeight / 2 - (height / 2)

    window.open(twitterUrl, "", `width=${width},height=${height},left=${left},top=${top}`)
  }
}

// Utility to open a Facebook share window
export const openFacebookWindow = () => {
  if (isClientSide()) {
    const facebookUrl = `//facebook.com/sharer.php?u=${encodeURIComponent(window.location.href)}`

    const width = 640
    const height = 300
    // Centering the popup (doesn't work in all browsers)
    const left = (window.innerWidth / 2) - (width / 2)
    const top = window.innerHeight / 2 - (height / 2)

    window.open(facebookUrl, "", `width=${width},height=${height},left=${left},top=${top}`)
  }
}

export const openLinkedInWindow = () => {

  if (isClientSide()) {
    const url = window.location.href;
    const linkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`;

    const width = 640
    const height = 300
    // Centering the popup (doesn't work in all browsers)
    const left = (window.innerWidth / 2) - (width / 2)
    const top = window.innerHeight / 2 - (height / 2)

    window.open(linkedInUrl, "", `width=${width},height=${height},left=${left},top=${top}`)
  }
}

/* Check if two rectangles intersect */
export const isIntersecting = (rect1, rect2) => {
  return !(
    rect1.top > rect2.bottom ||
    rect1.right < rect2.left ||
    rect1.bottom < rect2.top ||
    rect1.left > rect2.right
  )
}

// It gets an image filename in input and checks if the icon dark theme is needed
export const isDarkTheme = (ImageFileName = "") => ImageFileName.includes('_d_')