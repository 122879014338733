import { css } from '@emotion/react'

const FormsCSS = css`
  input {
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--color--fog-600);
    font-size: var(--font-size--text-regular);
    font-family: var(--font-family--mono);
    outline: 0;
  }
`

export default FormsCSS
