/*
  `generateFluidCalc(Number, Number, Number, Number)`:

  This function generates a CSS calc() function that will calculate
  a fluid pixel value scaling between the `minValue` and `maxValue`
  in correlation with how far between the `viewportMin` and
  `viewportMax` the browser's viewport width is.
*/
export const generateFluidCalc = (
  minValue,
  maxValue,
  viewportMin,
  viewportMax
) => `calc(${minValue}px + (${maxValue} - ${minValue}) * ((100vw - ${viewportMin}px) / (${viewportMax} - ${viewportMin})))`
