import { css } from '@emotion/react'

import { generateFluidCalc } from './utils'
import { BREAKPOINTS } from '../../../constants'

export const SPACING_VALUES = {
  DEFAULT: {
    xxl: {
      max: 260,
      min: 130,
    },
    xl: {
      max: 160,
      min: 80,
    },
    l: {
      max: 100,
      min: 50,
    },
    m: {
      max: 60,
      min: 30,
    },
    s: {
      max: 40,
      min: 20,
    },
    xs: {
      max: 20,
      min: 10,
    },
  },
  XL: {
    xxl: {
      max: 325,
      min: 260,
    },
    xl: {
      max: 200,
      min: 160,
    },
    l: {
      max: 125,
      min: 100,
    },
    m: {
      max: 75,
      min: 60,
    },
    s: {
      max: 50,
      min: 40,
    },
    xs: {
      max: 25,
      min: 20,
    },
  },
}

const SpacingCSS = css`
  :root {
    ${Object.keys(SPACING_VALUES.DEFAULT).map(key => {
      const values = SPACING_VALUES.DEFAULT[key]
      return css`
        --spacing-${key}: max(
          ${values.min}px,
          ${generateFluidCalc(values.min, values.max, 375, 1600)}
        );
      `
    })}
  }

  @media (min-width: ${BREAKPOINTS.XL}) {
    :root {
      ${Object.keys(SPACING_VALUES.XL).map(key => {
        const values = SPACING_VALUES.XL[key]
        return css`
          --spacing-${key}: min(
            ${values.max}px,
            ${generateFluidCalc(values.min, values.max, 1600, 2000)}
          );
        `
      })}
    }
  }
`

export default SpacingCSS
