import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

/*
  For the illusion of the inifinite ticker animation to work without a visible break,
  make sure the * transform percentage * at the end of the animation 
  as well as the * margin-left * of TickerTextItemDIV are  in perfect harmony.
 */

const TickerKeyframes = keyframes`
    15% {
      transform: translate3d(0, 0, 0);
    }
    100% {
        transform : translate3d( var(--end-position), 0, 0)
    }
`;

//The same size as parent container and holds list of scrolling text
export const TickerTextWrapperSPAN = styled.span`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  font-size: inherit;
  -webkit-text-size-adjust: 100%;
`

//The text item itself
export const TickerTextItemSPAN = styled.span` 
  display:inline-block;

  &:not(:first-of-type) {
    margin-left: 50px;
  }  

  --end-position: ${ props => props.position };
  --duration: ${ props => props.duration };

  &:not(:first-of-type) {
    display: none;
    &.show-clone {
      display: block;
    }
  } 

  &.ticker-animation {
    animation: ${TickerKeyframes} var(--duration) linear infinite;
    animation-delay: 1s;
  }
`
