import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

import IconApplePodcasts from "./svgs/IconApplePodcasts"
import IconArrowActionable from "./svgs/IconArrowActionable"
import IconArrowActionableThick from "./svgs/IconArrowActionableThick"
import IconArrowRight from "./svgs/IconArrowRight"
import IconAudioMediaButton from "./svgs/IconAudioMediaButton"
import IconCaretRight from "./svgs/IconCaretRight"
import IconCheckmark from "./svgs/IconCheckmark"
import IconChevronDown from "./svgs/IconChevronDown"
import IconClose from "./svgs/IconClose"
import IconEmail from "./svgs/IconEmail"
import IconFacebook from "./svgs/IconFacebook"
import IconHamburger from "./svgs/IconHamburger"
import IconInstagram from "./svgs/IconInstagram"
import IconLinkedIn from "./svgs/IconLinkedIn"
import IconLoading from "./svgs/IconLoading"
import IconPause from "./svgs/IconPause"
import IconPlay from "./svgs/IconPlay"
import IconReload from "./svgs/IconReload"
import IconSearch from "./svgs/IconSearch"
import IconSpotify from "./svgs/IconSpotify"
import IconTweetTooltip from "./svgs/IconTweetTooltip"
import IconTweetTooltipLeft from "./svgs/IconTweetTooltipLeft"
import IconTwitter from "./svgs/IconTwitter"

export const ICON_TYPES = {
  APPLE_PODCASTS: "APPLE_PODCASTS",
  ARROW_ACTIONABLE: "ARROW_ACTIONABLE",
  ARROW_ACTIONABLE_THICK: "ARROW_ACTIONABLE_THICK",
  ARROW_RIGHT: "ARROW_RIGHT",
  AUDIO_PLAY: "AUDIO_PLAY",
  CARET_RIGHT: "CARET_RIGHT",
  CHECKMARK: "CHECKMARK",
  CHEVRON_DOWN: "CHEVRON_DOWN",
  CLOSE: "CLOSE",
  EMAIL: "EMAIL",
  FACEBOOK: "FACEBOOK",
  HAMBURGER: "HAMBURGER",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  LOADING: "LOADING",
  PAUSE: "PAUSE",
  PLAY: "PLAY",
  RELOAD: "RELOAD",
  SEARCH: "SEARCH",
  SPOTIFY: "SPOTIFY",
  TWEET_TOOLTIP: "TWEET_TOOLTIP",
  TWEET_TOOLTIP_LEFT: "TWEET_TOOLTIP_LEFT",
  TWITTER: "TWITTER",
}

const SpinKeyframes = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
`

const IconLoadingStyled = styled(IconLoading)`
  animation: 1.8s ${SpinKeyframes} linear infinite;
`

const Icon = ({
  type,
  darkTheme
}) => {
  switch (type) {
    
    case ICON_TYPES.ARROW_RIGHT:
      return <IconArrowRight className={`Icon IconArrowRight`} />

    case ICON_TYPES.ARROW_ACTIONABLE:
      return <IconArrowActionable calssName={`Icon IconArrowActionable`} />

    case ICON_TYPES.ARROW_ACTIONABLE_THICK:
      return <IconArrowActionableThick calssName={`Icon IconArrowActionableThick`} />

    case ICON_TYPES.CARET_RIGHT:
      return <IconCaretRight className={`Icon IconCaretRight${darkTheme ? "--dark" : ""}`} />

    case ICON_TYPES.CHEVRON_DOWN:
      return <IconChevronDown className={`Icon IconChevronDown${darkTheme ? "--dark" : ""}`} />

    case ICON_TYPES.CLOSE:
      return <IconClose className="Icon IconClose" />

    case ICON_TYPES.EMAIL:
      return <IconEmail className="Icon IconEmail" />

    case ICON_TYPES.FACEBOOK:
      return <IconFacebook className="Icon IconFacebook" />

    case ICON_TYPES.HAMBURGER:
      return <IconHamburger className="Icon IconHamburger" />

    case ICON_TYPES.LOADING:
      return <IconLoadingStyled className="Icon IconLoading" />

    case ICON_TYPES.PAUSE:
      return <IconPause className="Icon IconPause" />

    case ICON_TYPES.PLAY:
      return <IconPlay className="Icon IconPlay" />

    case ICON_TYPES.RELOAD:
      return <IconReload className="Icon IconReload" />

    case ICON_TYPES.SEARCH:
      return <IconSearch className="Icon IconSearch" />

    case ICON_TYPES.TWEET_TOOLTIP:
      return <IconTweetTooltip className="Icon IconTweetTooltip" />

    case ICON_TYPES.TWEET_TOOLTIP_LEFT:
      return <IconTweetTooltipLeft className="Icon IconTweetTooltipLeft" />

    case ICON_TYPES.TWITTER:
      return <IconTwitter className="Icon IconTwitter" />

    case ICON_TYPES.INSTAGRAM:
      return <IconInstagram className="Icon IconInstagram" />

    case ICON_TYPES.LINKEDIN:
      return <IconLinkedIn className="Icon IconLinkedIn" />

    case ICON_TYPES.APPLE_PODCASTS:
      return <IconApplePodcasts className="Icon IconApplePodcasts" />

    case ICON_TYPES.SPOTIFY:
      return <IconSpotify className="Icon IconSpotify" />

    case ICON_TYPES.AUDIO_PLAY:
      return <IconAudioMediaButton className="Icon IconAudioPlay" />

    case ICON_TYPES.CHECKMARK:
      return <IconCheckmark className="Icon IconCheckmark" />

    default:
      return null
  }
}

export default Icon
