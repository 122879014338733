export const EXCLUDED_POST_ID = "88057fc7-64e6-5c0b-874a-1dfae6bf6382"

export const BREAKPOINTS = {
  S: '425px',
  M: '768px',
  L: '1024px',
  XL: '1600px',
  XXL: '2000px',
};

export const MEDIA_QUERIES = {
  S: `@media (min-width: ${BREAKPOINTS.S})`,
  M: `@media (min-width: ${BREAKPOINTS.M})`,
  L: `@media (min-width: ${BREAKPOINTS.L})`,
  XL: `@media (min-width: ${BREAKPOINTS.XL})`,
  TOUCH: `@media (hover: none)`,
  MOUSE: `@media (hover: hover)`,
}

export const PROMO_BAR_HEIGHT = 59;

export const HEADER_MAIN_NAV_HEIGHTS = {
  S: 80,
  M: 100,
}

export const HEADER_MAIN_SUBNAV_HEIGHT = 60;


// cookies / newsletter
export const COOKIE_NAME_NEWSLETTER_NOTHANKS = 'newsletter_nothanks';
export const COOKIE_NAME_NEWSLETTER_SUBSCRIBER = 'newsletter_subscriber';

export const CUSTOM_EVENTS = {
  HEADER_OVERLAY_OPEN: "headerOverlayOpen",
  HEADER_OVERLAY_CLOSED: "headerOverlayClosed",
}