import { css } from '@emotion/react'

const ColorsCSS = css`
  :root {
    /* Fog */
    --color--fog-200: #FCFBF9;
    --color--fog-300: #F6F5F3;
    --color--fog-400: #EDECEB;
    --color--fog-500: #E5E4E3;
    --color--fog-600: #DDDCDA;
    --color--fog-700: #D4D3D2;
    --color--fog-800: #CCCBCA;

    /* Graphite */
    --color--graphite-200: #909CA3;
    --color--graphite-300: #7F8A90;
    --color--graphite-400: #667075;
    --color--graphite-500: #5D666B;
    --color--graphite-600: #4B5358;
    --color--graphite-700: #3A4146;
    --color--graphite-800: #292F33;

    /* Accents + */
    --color--accents-plus-200: #FF7B59;
    --color--accents-plus-300: #FFB069;
    --color--accents-plus-400: #FCD936;
    --color--accents-plus-500: #70DBB4;
    --color--accents-plus-600: #61B5D1;
    --color--accents-plus-700: #C49CC9;
    --color--accents-plus-800: #CE698B;

    /* Accents - */
    --color--accents-minus-200: #801A00;
    --color--accents-minus-300: #A14912;
    --color--accents-minus-400: #976A20;
    --color--accents-minus-500: #3B471F;
    --color--accents-minus-600: #0B4B60;
    --color--accents-minus-700: #544550;
    --color--accents-minus-800: #632237;

    /* Tints + */
    --color--tints-plus-200: #FDF4F2;
    --color--tints-plus-300: #F5F2F0;
    --color--tints-plus-400: #F5F3F0;
    --color--tints-plus-500: #F1F4F3;
    --color--tints-plus-600: #EEF7F6;
    --color--tints-plus-700: #EFEDF7;
    --color--tints-plus-800: #F2EEEE;

    /* Tints - */
    --color--tints-minus-200: #FAD8D1;
    --color--tints-minus-300: #E1D1C7;
    --color--tints-minus-400: #DBD3BD;
    --color--tints-minus-500: #C6D2CE;
    --color--tints-minus-600: #DAEDEC;
    --color--tints-minus-700: #C6C4D4;
    --color--tints-minus-800: #D0C2C2;

    /* UI */
    --color--actionable: #976A20;
    --color--actionable-minus: #93651A;
    --color--actionable-minus-minus: #8C5F17;

    /* Selection */
    --color--selection-default: rgba(218, 237, 236, .9);
    --color--selection-img-default: rgba(218, 237, 236, .5);
    --color--selection-200: rgba(255, 123, 89, .2); // rgba(128, 26, 0, .5);
    --color--selection-300: rgba(255, 176, 105, .2); // rgba(161, 73, 18, .5);
    --color--selection-400: rgba(252, 217, 54, .2); // rgba(151, 106, 32, .5);
    --color--selection-500: rgba(112, 219, 180, .2); // rgba(59, 71, 31, .5);
    --color--selection-600: rgba(97, 181, 209, .2); // rgba(59, 71, 31, .5);
    --color--selection-700: rgba(196, 156, 201, .2); // rgba(84, 69, 80, .5);
    --color--selection-800: rgba(206, 105, 139, .2); // rgba(99, 34, 55, .5);

    /* FOCUS VISIBLE BOX-SHADOW */
    --box-shadow--focus-visible: 0 0 0 2px white, 0 0 0 4px var(--color--actionable);

    /* PRE-FOOTER CTA BACKGROUND COLOR */
    --color--pre-footer-cta: var(--color--tints-minus-200);
  }

  /*
    Global Colors
  */
  body {
    background: white;
    color: var(--color--graphite-400);
  }

  a,
  button {
    text-decoration: none;
    border-bottom: 1px solid var(--color--actionable);
    cursor: pointer;
    color: inherit;
    transition: color 0.25s;
    -webkit-appearance: none !important;

    &:hover {
      color: var(--color--actionable);
    }

    &:focus-visible {
      background: var(--color--fog-200);
      border-bottom: none;
      border-radius: 4px;
      box-shadow: var(--box-shadow--focus-visible);
      outline: 0;
    }
  }

  ::selection {
    background: var(--color--selection-default);
  }
  ::-moz-selection {
    background: var(--color--selection-default);
  }

  img::selection {
    background: var(--color--selection-img-default);
  }

  .selection--200 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-200);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--300 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-300);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--400 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-400);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--500 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-500);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--600 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-600);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--700 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-700);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }

  .selection--800 {
    h1::selection,
    h2::selection,
    h3::selection,
    h5::selection,
    p::selection,
    div::selection,
    span::selection,
    a::selection {
      background: var(--color--selection-800);
    }

    img::selection {
      background: var(--color--selection-img-default);
    }
  }
`

export default ColorsCSS
