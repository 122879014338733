/* eslint-disable no-unused-vars */
/*
  This component is used in gatsby-browser.js to implement a persistent Wistia audio
  player across pages after a WistaAudio component has loaded a new Wistia embed ID
  into the WistiaAudioContext (that is also implemented in gatsby-browser.js)

  NOTE: This component should never be used outside of gatsby-browser.js!
*/

import React, { useContext, useRef } from "react"
import { useSpring } from "react-spring"

import { convertSecondsToHHMMSS, isClientSide } from "../../utils"

import { WistiaAudioContext } from "../../context/WistiaAudioContext/WistiaAudioContext"

import Icon, { ICON_TYPES } from "../Icon/Icon"
import Ticker from "../Animations/Ticker/Ticker"

import {
  WistiaAudioGlobalPlayerCloseBUTTON,
  WistiaAudioGlobalPlayerContentDIV,
  WistiaAudioGlobalPlayerControlsDIV,
  WistiaAudioGlobalPlayerDIV,
  WistiaAudioGlobalPlayerContainerDIV,
  WistiaAudioGlobalPlayerGridDIV,
  WistiaAudioGlobalPlayerPlayBUTTON,
  WistiaAudioGlobalPlayerStatusBUTTON,
  WistiaAudioGlobalPlayerLinksDIV,
  timecodeMarginStyle
} from "./WistiaAudioGlobalPlayer.styles"


const WistiaAudioGlobalPlayer = () => {
  const {
    accentHue,
    applePodcasts,
    currentPlayer,
    playPause,
    seek,
    spotify,
    stop
  } = useContext(WistiaAudioContext)

  const StatusButtonRef = useRef()
  const accentColor = accentHue ? `var(--color--accents-minus-${accentHue})` : "var(--color--accents-minus-700)"

  const slideInStyles = useSpring({
    opacity: currentPlayer ? 1 : 0,
    y: currentPlayer ? "0%" : "100%",
    display: currentPlayer ? "block" : "none",
  })

  const handleClickPlay = e => {
    e.preventDefault()

    playPause(currentPlayer.id, e)
  }

  const handleClickClose = e => {
    e.preventDefault()

    stop()
  }

  const handleClickSeek = e => {
    e.preventDefault()

    const statusRect = StatusButtonRef.current.getBoundingClientRect()
    const percentClicked = (e.pageX - statusRect.x) / statusRect.width
    const percentInSeconds = Math.floor(percentClicked * currentPlayer.duration)

    seek(currentPlayer.id, percentInSeconds)
  }

  // Only render on the client-side!
  return isClientSide() ? (
    <WistiaAudioGlobalPlayerDIV style={slideInStyles}>
      {currentPlayer && (
        <WistiaAudioGlobalPlayerGridDIV>
          <WistiaAudioGlobalPlayerContainerDIV>
            <WistiaAudioGlobalPlayerCloseBUTTON
              onClick={handleClickClose}
            >
              <Icon type={ICON_TYPES.CLOSE} />
            </WistiaAudioGlobalPlayerCloseBUTTON>
            <WistiaAudioGlobalPlayerStatusBUTTON
              onClick={handleClickSeek}
              ref={StatusButtonRef}
              color={accentColor}
            >
              <div
                style={{
                  width: `${currentPlayer.time / currentPlayer.duration * 100}%`,
                }}
              />
            </WistiaAudioGlobalPlayerStatusBUTTON>
            <WistiaAudioGlobalPlayerControlsDIV>
              <WistiaAudioGlobalPlayerPlayBUTTON
                className={currentPlayer.isLoading ? "is-loading" : currentPlayer.isPlaying ? "is-playing" : ""}
                onClick={handleClickPlay}
                accentColor={accentColor}
              >
                {
                  currentPlayer.isLoading ? (
                    <Icon type={ICON_TYPES.LOADING} />
                  ) : currentPlayer.isPlaying ? (
                    <Icon type={ICON_TYPES.PAUSE} />
                  ) : (
                    <Icon type={ICON_TYPES.PLAY} />
                  )
                }
              </WistiaAudioGlobalPlayerPlayBUTTON>
              <WistiaAudioGlobalPlayerContentDIV>
                {currentPlayer.title &&
                  currentPlayer.isPlaying
                  ? <h5><Ticker text={currentPlayer.title} /></h5>
                  : <h5>{currentPlayer.title}</h5>
                }
                <p className="overline overline-s">
                  {currentPlayer.isLoading ? (
                    "Loading..."
                  ) : (
                    <>
                      {currentPlayer.time ? convertSecondsToHHMMSS(currentPlayer.time) : "0:00"}
                      <span css={timecodeMarginStyle}>/</span>
                      {currentPlayer.duration ? convertSecondsToHHMMSS(currentPlayer.duration) : "0:00"}
                    </>
                  )}
                </p>
              </WistiaAudioGlobalPlayerContentDIV>
            </WistiaAudioGlobalPlayerControlsDIV>

            <WistiaAudioGlobalPlayerLinksDIV>
              {applePodcasts && (
                <a
                  title="Listen on Apple Podcasts"
                  href={applePodcasts}
                  target="_blank" rel="noreferrer"
                >
                  <Icon type={ICON_TYPES.APPLE_PODCASTS} />
                </a>
              )
              }
              {spotify && (
                <a
                  title="Listen on Spotify"
                  href={spotify}
                  target="_blank" rel="noreferrer"
                >
                  <Icon type={ICON_TYPES.SPOTIFY} />
                </a>
              )
              }
            </WistiaAudioGlobalPlayerLinksDIV>

          </WistiaAudioGlobalPlayerContainerDIV>
        </WistiaAudioGlobalPlayerGridDIV>
      )}
    </WistiaAudioGlobalPlayerDIV >
  ) : null
}

export default WistiaAudioGlobalPlayer
