/*
  `useWistiaScript`
  This hook can be used to include Wistia's E-v1.js script, which is necessary for 
  any Wistia embeds. In addition, the hook will return the status of whether or not
  the `window.Wistia` object is available and that the Wistia embed API is ready
  for use.
*/
import { useEffect, useRef, useState } from "react"

import { isClientSide } from "../../utils"

const useWistiaScript = isNeeded => {
  const scriptTimerRef = useRef()
  const [isWistiaReady, setIsWistiaReady] = useState(false)

  useEffect(() => {
    if (!isClientSide() || !isNeeded) return

    /*
      Add Wistia Embed script
    */
    const WISTIA_EV1_SRC = "https://fast.wistia.com/assets/external/E-v1.js"

    const existingWistiaScriptEl = Array.from(document.querySelectorAll("script")).find(el => el.src.includes(WISTIA_EV1_SRC))
  
    if (!existingWistiaScriptEl) {
      const wistiaScriptEl = document.createElement("script")
      wistiaScriptEl.src = WISTIA_EV1_SRC
      wistiaScriptEl.defer = true
      wistiaScriptEl.type = "text/javascript"
      document.getElementsByTagName("head")[0].appendChild(wistiaScriptEl)
    }

    const waitForWistia = () => {
      // Wait for Wistia to be available`
      if (!window.Wistia) {
        scriptTimerRef.current = setTimeout(waitForWistia, 100)
        return
      }
      setIsWistiaReady(true)
    }

    waitForWistia()

    return () => {
      if (scriptTimerRef.current) clearTimeout(scriptTimerRef.current)
    }
  }, [isNeeded])

  return isWistiaReady
}

export default useWistiaScript
