/* eslint-disable no-unused-vars */
// Added this eslint-disable line to prevent warnings about `isWistiaReady`
import React, {
  createContext,
  useReducer,
  useRef,
  useState,
} from "react"

import useWistiaScript from "../../hooks/useWistiaScript/useWistiaScript"

import {
  WISTIA_REDUCER_ACTIONS,
  WistiaReducer,
} from "./WistiaAudioContext.reducer"

const CONTEXT_DEFAULTS = {
  changeCurrentPlayer: () => null,
  currentId: null,
  currentPlayer: {},
  title: "",
}

const EMBED_TIMEOUT = 100

export const WistiaAudioContext = createContext(CONTEXT_DEFAULTS)

export const WistiaAudioContextProvider = ({
  children,
}) => {
  const [isWistiaNeeded, setIsWistiaNeeded] = useState(false)
  const isWistiaReady = useWistiaScript(isWistiaNeeded)

  const [currentId, setCurrentId] = useState(null)
  const [state, dispatch] = useReducer(WistiaReducer, {})

  const EmbedPlayerTimeoutsRef = useRef({})

  const addPlayer = player => {
    if (state[player.id]) return

    dispatch({
      type: WISTIA_REDUCER_ACTIONS.ADD_PLAYER,
      ...player,
    })
  }

  const embedPlayer = id => {
    if (EmbedPlayerTimeoutsRef.current[id]) clearTimeout(EmbedPlayerTimeoutsRef.current[id])

    if (state[id].wistiaPlayer) return

    if (!window.Wistia) {
      EmbedPlayerTimeoutsRef.current[id] = setTimeout(() => {
        embedPlayer(id)
      }, EMBED_TIMEOUT)
      return
    }

    // Grab the audio player using the Wistia api
    const wistiaPlayer = window.Wistia.api(id)

    if (!wistiaPlayer) {
      EmbedPlayerTimeoutsRef.current[id] = setTimeout(() => {
        embedPlayer(id)
      }, EMBED_TIMEOUT)
      return
    }

    dispatch({
      type: WISTIA_REDUCER_ACTIONS.ADD_WISTIA_PLAYER,
      id,
      wistiaPlayer,
    })

    wistiaPlayer.bind("play", () => {
      // Set isLoading to false the first time this player is played (and everytime after)
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.SET_IS_LOADING,
        id,
        isLoading: false,
      })
      // Add the duration to the player data
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.ADD_DURATION,
        id,
        duration: wistiaPlayer.duration(),
      })
      // Set isPlaying to true
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.SET_IS_PLAYING,
        id,
        isPlaying: true,
      })
    })

    wistiaPlayer.bind("pause", () => {
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.SET_IS_PLAYING,
        id,
        isPlaying: false,
      })
    })

    wistiaPlayer.bind("end", () => {
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.SET_IS_PLAYING,
        id,
        isPlaying: false,
      })
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.TIME_CHANGE,
        id,
        time: 0,
      })
      setCurrentId(null)
    })

    wistiaPlayer.bind("timechange", time => {
      dispatch({
        type: WISTIA_REDUCER_ACTIONS.TIME_CHANGE,
        id,
        time,
      })
    })

    // Stop any players that are already playing
    Object.keys(state).forEach(id => {
      state[id].wistiaPlayer?.pause()
      state[id].wistiaPlayer?.time(0)
    })

    // Autoplay the player that was just embedded
    wistiaPlayer.play()
  }

  const playPause = id => {
    setCurrentId(id)
    setIsWistiaNeeded(true)

    // If this audio has yet to be played, embed it (which will also autoplay the audio!)
    if (!state[id].wistiaPlayer) {
      embedPlayer(id)
      return
    }

    if (state[id].isPlaying) {
      state[id].wistiaPlayer.pause()
    } else {
      // Pause and reset any players that are currently playing
      Object.keys(state).forEach(_id => {
        if (id === _id) return
        state[_id].wistiaPlayer?.pause()
        state[_id].wistiaPlayer?.time(0)
      })

      state[id].wistiaPlayer.play()
    }
  }

  const seek = (id, percentInSeconds) => {
    state[id].wistiaPlayer?.time(percentInSeconds)
  }

  const stop = () => {
    setCurrentId(null)

    // Pause and reset any players that are currently playing
    Object.keys(state).forEach(id => {
      state[id].wistiaPlayer?.pause()
      state[id].wistiaPlayer?.time(0)
    })
  }

  /*
    RENDER
  */
  return (
    <WistiaAudioContext.Provider
      value={{
        addPlayer,
        applePodcasts: state[currentId]?.applePodcasts,
        currentPlayer: state[currentId],
        playPause,
        seek,
        spotify: state[currentId]?.spotify,
        stop,
      }}
    >
      {children}
      {/* WISTIA embed divs: these will be initialized automatically by Wistia's E-v1.js */}
      {Object.keys(state).map(id => (
        <div
          key={id}
          className={`wistia_embed wistia_async_${id}`}
          // Hide the default player
          style={{
            position: "absolute",
            bottom: 0,
            left: "-100vw",
            zIndex: "-1000",
            opacity: 0,
            pointerEvent: "none",
          }}
        />
      ))}
    </WistiaAudioContext.Provider>
  )
}