import { css } from '@emotion/react'

import { BREAKPOINTS } from '../../../constants'
import { generateFluidCalc } from './utils'

export const FONT_SIZES = {
  DEFAULT: {
    h1: {
      max: 64,
      min: 42,
    },
    h2: {
      max: 48,
      min: 36,
    },
    h3: {
      max: 36,
      min: 30,
    },
    h4: {
      max: 30,
      min: 24,
    },
    h5: {
      max: 24,
      min: 21,
    },
    h6: {
      max: 21,
      min: 18,
    },
    "text-regular": {
      max: 21,
      min: 18,
    },
    "text-s": {
      max: 18,
      min: 15,
    },
    "text-xs": {
      max: 15,
      min: 14,
    },
    "text-xxs": {
      max: 12,
      min: 12,
    },
    "blockquote-l": {
      max: 42,
      min: 30,
    },
    "blockquote-s": {
      max: 30,
      min: 24,
    },
    "overline-l": {
      max: 24,
      min: 21,
    },
    "overline-m": {
      max: 18,
      min: 15,
    },
    "overline-s": {
      max: 15,
      min: 14,
    },
    "link-l": {
      max: 20,
      min: 18,
    },
    "link-m": {
      max: 18,
      min: 15,
    },
    "link-s": {
      max: 15,
      min: 14,
    },
  },
  XL: {
    h1: {
      max: 84,
      min: 64,
    },
    h2: {
      max: 64,
      min: 48,
    },
    h3: {
      max: 48,
      min: 36,
    },
    h4: {
      max: 36,
      min: 30,
    },
    h5: {
      max: 30,
      min: 24,
    },
    h6: {
      max: 24,
      min: 21,
    },
    "text-regular": {
      max: 24,
      min: 21,
    },
    "text-m": {
      max: 24,
      min: 21,
    },
    "text-s": {
      max: 21,
      min: 18,
    },
    "text-xs": {
      max: 18,
      min: 15,
    },
    "text-xxs": {
      max: 15,
      min: 12,
    },
    "blockquote-l": {
      max: 48,
      min: 42,
    },
    "blockquote-s": {
      max: 36,
      min: 30,
    },
    "overline-l": {
      max: 30,
      min: 24,
    },
    "overline-m": {
      max: 21,
      min: 18,
    },
    "overline-s": {
      max: 18,
      min: 15,
    },
    "link-l": {
      max: 24,
      min: 20,
    },
    "link-m": {
      max: 20,
      min: 18,
    },
    "link-s": {
      max: 18,
      min: 15,
    },
  },
}

const TypographyCSS = css`
  /*
    Variables
  */
  :root {
    ${Object.keys(FONT_SIZES.DEFAULT).map(key => {
      const values = FONT_SIZES.DEFAULT[key]
      return css`
        --font-size--${key}: max(
          ${values.min}px,
          ${generateFluidCalc(values.min, values.max, 375, 1600)}
        );
      `
    })}

    --line-height--l: 1.6;
    --line-height--m: 1.4;
    --line-height--s: 1.2;
    --line-height--xs: 1;

    --font-family--regular: "GT America";
    --font-family--extended: "GT America Extended";
    --font-family--condensed: "GT America Condensed";
    --font-family--mono: "GT America Mono";

    --font-weight--regular: 400;
    --font-weight--bold: 700;
    --font-weight--black: 900;
  }

  /*
    Variables at XL viewports
  */
  @media (min-width: ${BREAKPOINTS.XL}) {
    :root {
      ${Object.keys(FONT_SIZES.XL).map(key => {
        const values = FONT_SIZES.XL[key]
        return css`
          --font-size--${key}: min(
            ${values.max}px,
            ${generateFluidCalc(values.min, values.max, 1600, 2000)}
          );
        `
      })}
    }
  }

  /*
    Style declarations
  */
  body {
    font-family: var(--font-family--regular);
    line-height: var(--line-height--l);
  }

  h1,
  h2,
  h3,
  .h1,
  .h2,
  .h3 {
    font-family: var(--font-family--condensed);
    font-weight: var(--font-weight--black);
    line-height: var(--line-height--xs);
    letter-spacing: -0.01em;
    margin: 0 0 var(--spacing-s);
    color: var(--color--graphite-800);
  }

  h1,
  .h1 {
    font-size: var(--font-size--h1);
  }

  h2,
  .h2 {
    font-size: var(--font-size--h2);
  }

  h3,
  .h3 {
    font-size: var(--font-size--h3);
    margin: 0 0 var(--spacing-xs);
  }

  h4,
  h5,
  h6,
  .h4,
  .h5,
  .h6 {
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--s);
    margin: 0 0 var(--spacing-xs);
    color: var(--color--graphite-800);
  }

  h4,
  .h4 {
    font-size: var(--font-size--h4);
  }

  h5,
  .h5 {
    font-size: var(--font-size--h5);
  }

  h6,
  .h6 {
    font-size: var(--font-size--h6);
  }

  .subheadline {
    font-weight: var(--font-weight--regular);
    color: var(--color--graphite-400);
  }

  p,
  .text-regular {
    font-size: var(--font-size--text-regular);
    margin: 0 0 var(--spacing-xs);
  }

  .text-s {
    font-size: var(--font-size--text-s);
    margin: 0 0 var(--spacing-xs);
  }

  .text-xs {
    font-size: var(--font-size--text-xs);
    margin: 0 0 var(--spacing-xs);
  }

  blockquote,
  .blockquote-s {
    font-family: var(--font-family--extended);
    font-weight: var(--font-weight--bold);
    letter-spacing: -0.01em;
    line-height: var(--line-height--s);
    color: var(--color--graphite-500);
  }

  blockquote {
    font-size: var(--font-size--blockquote-l);
    margin: var(--spacing-m) 0;
  }

  .blockquote-s {
    font-size: var(--font-size--blockquote-s);
    margin: var(--spacing-s) 0;
  }

  .overline {
    font-family: var(--font-family--mono);
    font-weight: var(--font-weight--regular);
    line-height: var(--line-height--s);
    letter-spacing: -0.01em;
    margin: 0 0 var(--spacing-xs);
    color: var(--color--graphite-500);

    &.overline-l {
      font-size: var(--font-size--overline-l);
    }

    &.overline-m {
      font-size: var(--font-size--overline-m);
    }

    &.overline-s {
      font-size: var(--font-size--overline-s);
    }
  }

  .link {
    font-weight: var(--font-weight--bold);
    font-size: var(--font-size--link-m);
    border: none;

    a& {
      color: var(--color--actionable);

      &:hover {
        color: var(--color--actionable-minus-minus);
      }
    }

    &.link-l {
      font-size: var(--font-size--link-l);
    }

    &.link-s {
      font-size: var(--font-size--link-s);
    }
  }

  button {
    font-family: var(--font-family--regular);
  }
`

export default TypographyCSS
