const subscribeEvent = (eventName: string, listener: () => void ) => {
  document.addEventListener(eventName, listener);
}

const unsubscribeEvent = (eventName: string, listener: () => void ) => {
  document.removeEventListener(eventName, listener);
}

const publishEvent = (eventName: string, data?: string) => {
  const _event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(_event);
}

export { publishEvent, subscribeEvent, unsubscribeEvent};