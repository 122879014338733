import memoize from "lodash/memoize"
import { getGatsbyImageData } from "@imgix/gatsby"

import { isProduction } from "./_env"

/*
  Image/Imgix utilities
  Note: Imgix is only used for production. On Staging and Local the files will be referenced directly.
*/
const IMGIX_DOMAIN = "https://intheworks.imgix.net"

export const getImgixImageData = memoize(
  (imgPath, width, aspectRatio, layout = "constrained") => (
    isProduction() ?
      getGatsbyImageData({
        aspectRatio,
        imgixParams: {
          auto: ["format", "compress"],
          placeholder: "dominantColor",
        },
        layout,
        src: `${IMGIX_DOMAIN}${imgPath}`,
        width,
      }) : {
        images: {
          sources: [],
          fallback: {
            src: imgPath,
            sizes: `(min-width: ${width}px) ${width}px, 100vw`,
          }
        },
        layout,
        width,
        height: width / aspectRatio,
      }
  )
  // Hash function for memoize to size duplicate image paths properly
  , (imgPath, width, aspectRatio, layout = "constrained") => `${imgPath}${width}${aspectRatio}${layout}`
)

export const getImgixUrl = (imgPath, width, height, dpr = 1) =>
  isProduction() ?
    `${IMGIX_DOMAIN}${imgPath}?auto=compress,format&fit=scale${width ? `&w=${width}` : ""}${height ? `&h=${height}` : ""}&dpr=${dpr}` :
    imgPath

export const getImgixAttrs = (imgPath, width, height) => (
  (!isProduction() || imgPath.includes(".gif")) ?
    {
      src: imgPath
    } : {
      src: getImgixUrl(imgPath, width, height, 2),
      srcSet: `${getImgixUrl(imgPath, width, height, 1)}, ${getImgixUrl(imgPath, width, height, 2)} 2x, ${getImgixUrl(imgPath, width, height, 3)} 3x`,
    }
)