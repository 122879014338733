import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { animated } from "react-spring"

import { BREAKPOINTS } from "../../constants"

export const WistiaAudioGlobalPlayerDIV = styled(animated.div)`
  padding: calc(var(--spacing-s) - 20px) 0 var(--spacing-s);
  position:sticky;
  bottom:0;
  left:0;
  background: var(--color--fog-200);
  z-index: 10;
`

export const WistiaAudioGlobalPlayerGridDIV = styled.div`
  width: var(--container-width);
  margin: 0 auto;

  @media (min-width: ${BREAKPOINTS.L}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 60px;
  }
`

export const WistiaAudioGlobalPlayerCloseBUTTON = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  right: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: var(--color--fog-300);
  transform: translateY(-50%);
  transition: background 0.3s;

  &:hover {
    background: var(--color--fog-400);
    color: var(--color--graphite-700);
  }
`

export const WistiaAudioGlobalPlayerContainerDIV = styled.div`
  position: relative;
  padding-right: calc(36px + var(--spacing-xs));

  @media (min-width: ${BREAKPOINTS.L}) {
    grid-area: 1 / 2 / 2 / 12;
    padding-right: calc(36px + var(--spacing-m));
  }
`

export const WistiaAudioGlobalPlayerPlayBUTTON = styled.button`
  flex: 0 0 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background:  ${ props => props.accentColor };
  opacity: 0.9;
  border: none;
  border-radius: 50px;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px white, 0 0 0 4px var(--color--actionable);
    border-radius: 50%;
  }

  svg {
    width: 21px;
    height: 24px;
    color: var(--color--fog-200);
    margin-left: 5px;
  }

  &.is-loading {
    opacity: 0.2;
    
    svg {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  &.is-playing {
    svg {
      width: 16px;
      height: 24px;
      margin-left: 0;
    }
  }
`

export const WistiaAudioGlobalPlayerStatusBUTTON = styled.button`
  position: relative;
  width: 100%;
  height: 46px;
  border-top: 20px solid var(--color--fog-200);
  border-bottom: 20px solid var(--color--fog-200);
  background: var(--color--fog-500);
  
  > div {
    position: absolute;
    width: 0;
    height: 6px;
    top: 50%;
    left: 0;
    background: ${props => props.color};
    transform: translateY(-50%);
  }
`

export const WistiaAudioGlobalPlayerControlsDIV = styled.div`
  display: flex;
`

export const WistiaAudioGlobalPlayerContentDIV = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: var(--spacing-xs);
  width:100%;
  overflow:hidden;
  white-space:nowrap;
  pointer-events:none;
  position:relative; 

  @media (max-width: 1180px) {
    &:after {
    content:'';
    width:33%;
    height:100%;    
    position:absolute;
    right:-36px;
    top:0;
    background: linear-gradient(90deg, rgba(252,251,249, 0.1), var(--color--fog-200) 88%);

      @media (max-width: ${BREAKPOINTS.M}) {
        width:50%;
        right:0;
        background: linear-gradient(90deg, rgba(252,251,249, 0.1), var(--color--fog-200) 80%);
      }
    }
  }
  

  p, h5 {
    margin: 0;
  }

  p.overline {
    line-height: var(--line-height--m);
  }
  @media (max-width: ${BREAKPOINTS.L}) {
    p.overline {
      line-height: var(--line-height--l);
    }   
  }

  h5 {
    font-size: var(--font-size--h6);
    margin-bottom: calc( var(--spacing-xs) / 2 );

    @media (min-width: ${BREAKPOINTS.S}) {
      margin-bottom: calc( var(--spacing-xs) / 4 );
    }
    
    @media (min-width: ${BREAKPOINTS.M}) {
      font-size: var(--font-size--h5);
    } 
  }   
`

export const WistiaAudioGlobalPlayerLinksDIV = styled.div`
    align-items: flex-end;
    position: absolute;
    width:auto;
    right: calc(36px + var(--spacing-xs));
    bottom:0;
    display: none;

    @media (min-width: 375px) {
      display: flex;
      align-items:center;
    }

    @media (min-width: ${BREAKPOINTS.L}) {
      right: calc(36px + var(--spacing-m));
    }

    a {
      border:none;
      line-height:0;

      /* Last icon */
      &:last-of-type {
        margin-left:10px;
      }

      &:hover, &:hover, &:focus-visible {
        path {
          transition: fill .3s;
        }

        path:nth-of-type(1) {
          fill:var(--color--actionable);
        }
        path:nth-of-type(2) {
          fill:var(--color--fog-200);
        }
      }

      &:focus-visible {
        border-radius: 4px;
        box-shadow: var(--box-shadow--focus-visible);
        outline: 0;
      }
    }      
`

export const timecodeMarginStyle = css`
  margin: 0px calc( var(--spacing-xs) / 2 );
`;
