import { css } from "@emotion/react"

/*
  `var(--column-1)`
  `var(--column-2)`
  ...
  `var(--column-12)`
  To be used whenever you need to set a CSS property based on grid column size
*/

const ColumnCSS = css`
  :root {
    ${[...Array(12)].map((_, i) => {
      const colSize = (i + 1) / 12;
      const offsetSize = (11 - i) / 12;
      return css`
        --column-${i + 1}: calc(var(--container-width) * ${colSize} - (var(--spacing-m) * ${offsetSize}));
      `
    })}
  }
`

export default ColumnCSS
