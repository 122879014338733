import React from "react"

const IconReload = props => (
  <svg width="28" height="29" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14" cy="14.5002" r="14" fill="#4B5358"/>
    <path d="M21.0467 18.1522L23.6297 14.7082H21.7397V14.6872C21.7397 10.0672 18.2957 7.02222 14.0957 7.02222C9.89569 7.02222 6.45169 10.0672 6.45169 14.6872C6.45169 19.2862 9.93769 22.3312 14.0957 22.3312C16.2587 22.3312 18.2117 21.5542 19.5977 20.1472L18.7787 19.1182C17.6447 20.2942 15.8807 21.0502 14.0957 21.0502C10.6097 21.0502 7.81669 18.5092 7.81669 14.6452C7.81669 10.7602 10.6097 8.30322 14.0957 8.30322C17.6027 8.30322 20.4377 10.8022 20.4377 14.6872V14.7082H18.4637L21.0467 18.1522Z" fill="currentColor"/>
  </svg>
)

export default IconReload
