import React from "react"
import Helmet from "react-helmet"
import { css } from "@emotion/react"

import GtAmericaCondensedBlackItalicTtf from "../../../fonts/GT-America-Condensed-Black-Italic.ttf"
import GtAmericaCondensedBlackItalicWoff from "../../../fonts/GT-America-Condensed-Black-Italic.woff"
import GtAmericaCondensedBlackItalicWoff2 from "../../../fonts/GT-America-Condensed-Black-Italic.woff2"

import GtAmericaCondensedBlackTtf from "../../../fonts/GT-America-Condensed-Black.ttf"
import GtAmericaCondensedBlackWoff from "../../../fonts/GT-America-Condensed-Black.woff"
import GtAmericaCondensedBlackWoff2 from "../../../fonts/GT-America-Condensed-Black.woff2"

import GtAmericaExtendedBoldItalicTtf from "../../../fonts/GT-America-Extended-Bold-Italic.ttf"
import GtAmericaExtendedBoldItalicWoff from "../../../fonts/GT-America-Extended-Bold-Italic.woff"
import GtAmericaExtendedBoldItalicWoff2 from "../../../fonts/GT-America-Extended-Bold-Italic.woff2"

import GtAmericaExtendedBoldTtf from "../../../fonts/GT-America-Extended-Bold.ttf"
import GtAmericaExtendedBoldWoff from "../../../fonts/GT-America-Extended-Bold.woff"
import GtAmericaExtendedBoldWoff2 from "../../../fonts/GT-America-Extended-Bold.woff2"

import GtAmericaMonoRegularItalicTtf from "../../../fonts/GT-America-Mono-Regular-Italic.ttf"
import GtAmericaMonoRegularItalicWoff from "../../../fonts/GT-America-Mono-Regular-Italic.woff"
import GtAmericaMonoRegularItalicWoff2 from "../../../fonts/GT-America-Mono-Regular-Italic.woff2"

import GtAmericaMonoRegularTtf from "../../../fonts/GT-America-Mono-Regular.ttf"
import GtAmericaMonoRegularWoff from "../../../fonts/GT-America-Mono-Regular.woff"
import GtAmericaMonoRegularWoff2 from "../../../fonts/GT-America-Mono-Regular.woff2"

import GtAmericaStandardBoldItalicTtf from "../../../fonts/GT-America-Standard-Bold-Italic.ttf"
import GtAmericaStandardBoldItalicWoff from "../../../fonts/GT-America-Standard-Bold-Italic.woff"
import GtAmericaStandardBoldItalicWoff2 from "../../../fonts/GT-America-Standard-Bold-Italic.woff2"

import GtAmericaStandardBoldTtf from "../../../fonts/GT-America-Standard-Bold.ttf"
import GtAmericaStandardBoldWoff from "../../../fonts/GT-America-Standard-Bold.woff"
import GtAmericaStandardBoldWoff2 from "../../../fonts/GT-America-Standard-Bold.woff2"

import GtAmericaStandardRegularItalicTtf from "../../../fonts/GT-America-Standard-Regular-Italic.ttf"
import GtAmericaStandardRegularItalicWoff from "../../../fonts/GT-America-Standard-Regular-Italic.woff"
import GtAmericaStandardRegularItalicWoff2 from "../../../fonts/GT-America-Standard-Regular-Italic.woff2"

import GtAmericaStandardRegularTtf from "../../../fonts/GT-America-Standard-Regular.ttf"
import GtAmericaStandardRegularWoff from "../../../fonts/GT-America-Standard-Regular.woff"
import GtAmericaStandardRegularWoff2 from "../../../fonts/GT-America-Standard-Regular.woff2"

const FontsCSS = css`
  @font-face {
    font-family: "GT America";
    font-display: swap;
    src: url(${GtAmericaStandardRegularWoff2}) format("woff2"),
      url(${GtAmericaStandardRegularWoff}) format("woff"),
      url(${GtAmericaStandardRegularTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America";
    font-display: swap;
    font-style: italic;
    src: url(${GtAmericaStandardRegularItalicWoff2}) format("woff2"),
      url(${GtAmericaStandardRegularItalicWoff}) format("woff"),
      url(${GtAmericaStandardRegularItalicTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America";
    font-display: swap;
    font-weight: 700;
    src: url(${GtAmericaStandardBoldWoff2}) format("woff2"),
      url(${GtAmericaStandardBoldWoff}) format("woff"),
      url(${GtAmericaStandardBoldTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America";
    font-display: swap;
    font-weight: 700;
    font-style: italic;
    src: url(${GtAmericaStandardBoldItalicWoff2}) format("woff2"),
      url(${GtAmericaStandardBoldItalicWoff}) format("woff"),
      url(${GtAmericaStandardBoldItalicTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Mono";
    font-display: swap;
    src: url(${GtAmericaMonoRegularWoff2}) format("woff2"),
      url(${GtAmericaMonoRegularWoff}) format("woff"),
      url(${GtAmericaMonoRegularTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Mono";
    font-display: swap;
    font-style: italic;
    src: url(${GtAmericaMonoRegularItalicWoff2}) format("woff2"),
      url(${GtAmericaMonoRegularItalicWoff}) format("woff"),
      url(${GtAmericaMonoRegularItalicTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Condensed";
    font-display: swap;
    font-weight: 900;
    src: url(${GtAmericaCondensedBlackWoff2}) format("woff2"),
      url(${GtAmericaCondensedBlackWoff}) format("woff"),
      url(${GtAmericaCondensedBlackTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Condensed";
    font-display: swap;
    font-weight: 900;
    font-style: italic;
    src: url(${GtAmericaCondensedBlackItalicWoff2}) format("woff2"),
      url(${GtAmericaCondensedBlackItalicWoff}) format("woff"),
      url(${GtAmericaCondensedBlackItalicTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Extended";
    font-display: swap;
    font-weight: 700;
    src: url(${GtAmericaExtendedBoldWoff2}) format("woff2"),
      url(${GtAmericaExtendedBoldWoff}) format("woff"),
      url(${GtAmericaExtendedBoldTtf})  format("truetype");
  }

  @font-face {
    font-family: "GT America Extended";
    font-display: swap;
    font-weight: 700;
    font-style: italic;
    src: url(${GtAmericaExtendedBoldItalicWoff2}) format("woff2"),
      url(${GtAmericaExtendedBoldItalicWoff}) format("woff"),
      url(${GtAmericaExtendedBoldItalicTtf})  format("truetype");
  }
`

const Fonts = () => (
  <Helmet>
    <link rel="preload" href={GtAmericaCondensedBlackItalicWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaCondensedBlackWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaExtendedBoldItalicWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaExtendedBoldWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaMonoRegularItalicWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaMonoRegularWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaStandardBoldItalicWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaStandardBoldWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaStandardRegularItalicWoff2} as="font" type="font/woff2" crossOrigin="" />
    <link rel="preload" href={GtAmericaStandardRegularWoff2} as="font" type="font/woff2" crossOrigin="" />
    <style id="font-face">{`${FontsCSS.styles}`}</style>
  </Helmet>
)

export default Fonts
