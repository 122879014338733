import React from "react"

const IconTweetTooltip = props => (
  <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M13 0C9.68631 0 7.00002 2.68629 7.00002 6V13.071L0 20.0711L7.00002 27.0711V34C7.00002 37.3137 9.68631 40 13 40H41C44.3137 40 47 37.3137 47 34V6C47 2.68629 44.3137 0 41 0H13Z" fill="#292F33" />
    <path fillRule="evenodd" clipRule="evenodd" d="M39.62 12.37C38.71 12.77 37.74 13.04 36.72 13.17C37.76 12.55 38.56 11.55 38.94 10.38C37.96 10.96 36.88 11.38 35.73 11.61C34.81 10.61 33.5 10 32.05 10C29.26 10 27 12.26 27 15.05C27 15.45 27.04 15.83 27.13 16.2C22.93 15.99 19.21 13.98 16.72 10.92C16.28 11.67 16.03 12.54 16.03 13.46C16.03 15.21 16.92 16.76 18.28 17.66C17.45 17.64 16.67 17.41 15.99 17.03V17.09C15.99 19.54 17.73 21.58 20.04 22.04C19.62 22.15 19.17 22.22 18.71 22.22C18.38 22.22 18.07 22.19 17.76 22.13C18.4 24.14 20.27 25.6 22.48 25.64C20.75 26.99 18.57 27.8 16.2 27.8C15.79 27.8 15.39 27.78 15 27.73C17.24 29.16 19.89 30 22.74 30C32.03 30 37.11 22.3 37.11 15.63L37.1 14.98C38.09 14.27 38.95 13.38 39.62 12.37Z" fill="#FCFBF9" />
  </svg>
)

export default IconTweetTooltip
