/*
  String/Number Utilities
*/

// Can use to prevent typographic "widows", or as we call'em: "sleepy buddies"
export const preventSleepyBuddies = str => str.replace(/\s([\S]+)$/, (_, p1) => `&nbsp${p1}`)

// Convert a string to kebab case, e.g. `This is a title` becomes `this-is-a-title`
export const convertStringToKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')

// Get substring of x words, without truncates/trim words
export const getSubstring = (string, words) => {
  return string.split(" ").splice(0, words).join(" ");
}

// remove whitespace
export const removeWhitepace = (text) => text?.replace(' ', '');

// Get substring of x words with ellipsis, this function truncates/trim words
export const getSubstringEllipsis = (string, chars) => string.length > chars ? `${string.substring(0, chars)}...` : string

// Trim leading zero
export const trimLeadingZero = (text) => {
  return text.replace(/^0+/, '')
}

// check if a number is Even
export const isEven = (num) => {
  return (num % 2 === 0)
}

// Converts seconds (Number) to (HH:)MM:SS format
export const convertSecondsToHHMMSS = s => {
  const hours = Math.floor(s / 3600)
  const minutes = Math.floor((s - (hours * 3600)) / 60)
  const seconds = Math.floor(s - (hours * 3600) - (minutes * 60))

  const output = []

  // Hours only displayed if needed
  if (hours) output.push(hours)

  // Minutes
  output.push(`${(hours && minutes < 10) ? "0" : ""}${minutes}`)

  // Seconds
  output.push(`${seconds < 10 ? "0" : ""}${seconds}`)

  return output.join(":")
}

// Make sure the phrase "In the Works" doesn't break
export const dontBreakITW = str => {
  const ITW = "In the Works";
  if (str && typeof str === "string" && str.includes(ITW)) {
    const nonBreakingItw = str.replace(ITW, "In&nbsp;the&nbsp;Works")
    return nonBreakingItw;
  } else return str;
};