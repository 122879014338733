import React from "react"

import { WistiaAudioContextProvider } from "./src/context/WistiaAudioContext/WistiaAudioContext"
import WistiaAudioGlobalPlayer from "./src/components/WistiaAudioGlobalPlayer/WistiaAudioGlobalPlayer"

import GlobalStyles from "./src/components/GlobalStyles/GlobalStyles"
 
/*
  NOTE:
  Third-party scripts should probably be loaded in this file.
*/

export const onRouteUpdate = () => {
  /*
    Remove Hubspot Form script on each page
    * Needs to be removed and re-added on each route update in order to initialize the forms on each page.
  */
  const HUBSPOT_FORM_SRC = "//js.hsforms.net/forms/v2.js"
  const existingHubspotScriptEl = Array.from(document.querySelectorAll("script")).find(el => el.src.includes(HUBSPOT_FORM_SRC))
  if (existingHubspotScriptEl) existingHubspotScriptEl.remove()

  // Send a pagview event to GA when the route changes
  if (window.ga) {
    window.ga("send", "pageview")
  }

  // Send a "Viewed Page" event to Mixpanel when the route changes
  if (window.mixpanel) {
    window.mixpanel.track("Viewed Page")
  }

}

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyles />
    <WistiaAudioContextProvider>
      {element}
      <WistiaAudioGlobalPlayer />
    </WistiaAudioContextProvider>
  </>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // Scroll to top of page for all routes
  window.scrollTo(0, 0)

  return false
}
