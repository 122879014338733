export const WISTIA_REDUCER_ACTIONS = {
  ADD_DURATION: "ADD_DURATION",
  ADD_ID: "ADD_ID",
  ADD_PLAYER: "ADD_PLAYER",
  SET_IS_LOADING: "SET_IS_LOADING",
  SET_IS_PLAYING: "SET_IS_PLAYING",
  TIME_CHANGE: "TIME_CHANGE",
}

export const WistiaReducer = (state, action) => {
  switch (action.type) {
    case WISTIA_REDUCER_ACTIONS.ADD_DURATION:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          duration: action.duration,
        },
      }

    case WISTIA_REDUCER_ACTIONS.ADD_PLAYER:
      return {
        ...state,
        [action.id]: {
          applePodcasts: action.applePodcasts,
          id: action.id,
          isLoading: true,
          isPlaying: false,
          link: action.link,
          spotify: action.spotify,
          title: action.title,
        },
      }

    case WISTIA_REDUCER_ACTIONS.ADD_WISTIA_PLAYER:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          wistiaPlayer: action.wistiaPlayer,
        },
      }

    case WISTIA_REDUCER_ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isLoading: action.isLoading,
        },
      }

    case WISTIA_REDUCER_ACTIONS.SET_IS_PLAYING:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          isPlaying: action.isPlaying,
        },
      }

    case WISTIA_REDUCER_ACTIONS.TIME_CHANGE:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          time: action.time,
        },
      }

    default:
      return state
  }
}
