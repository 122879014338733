/*
  Environment Check Utilities
*/

export const isProduction = () => !(process.env.GATSBY_IS_PREVIEW === true) && process.env.NODE_ENV === "production"

export const isClientSide = () => typeof window !== "undefined"

export const isMobile = (breakpoint = 1024) => isClientSide() && window.innerWidth < breakpoint

