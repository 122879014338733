import { css } from '@emotion/react'

import ColorsCSS from './_colors'
import ColumnCSS from './_columns'
import FormsCSS from './_forms'
import IconsCSS from './_icons'
import GridCSS from './_grid'
import ResetCSS from './_reset'
import SpacingCSS from './_spacing'
import TypographyCSS from './_typography'

const Styles = css`
  ${ResetCSS}
  ${GridCSS}
  ${ColumnCSS}
  ${ColorsCSS}
  ${SpacingCSS}
  ${FormsCSS}
  ${IconsCSS}
  ${TypographyCSS}
`

export default Styles
