import { css } from '@emotion/react'

const ResetCSS = css`
  html {
    -webkit-tap-highlight-color: transparent;
  }
  
  html,
  body {
    padding: 0;
    margin: 0;
    height: -webkit-fill-available;
    min-height: 100vh;
  }

  *,
  :after,
  :before,
  input[type=search] {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  button {
    -webkit-appearance: none;
    border: none;
    background: none;
    outline: 0;
  }
`

export default ResetCSS
