import React from "react"

const IconPause = props => (
  <svg width="17" height="26" viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x="0.424133" y="0.575752" width="5.45455" height="24.8485" rx="1.39286" fill="currentColor" />
    <rect x="10.7273" y="0.57576" width="5.45454" height="24.8485" rx="1.39286" fill="currentColor" />
  </svg>
)

export default IconPause
