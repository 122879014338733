import React, { memo, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { debounce } from "lodash"

import { 
  TickerTextWrapperSPAN,
  TickerTextItemSPAN
} from "./Ticker.styles"

const Ticker = ({ text }) => {  
  const tickerTextWrapperRef = useRef()

  const [windowSize, setWindowSize] = React.useState({ 
    width: null,
  })
  const [textShouldTick, setTextShouldTick] = useState(false)
  const [endPos, setEndPos] = useState('0%')
  const [duration, setDuration] = useState('8s')

  const NUM_CLONES = 2; // Number of child clones to create
  const MARGIN_LEFT = 50; // The margin between the text clones, minus container left
  const DURATION_CONSTANT = 10

  // Listen for window resize
  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window.innerWidth
      }, 1000)
    })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // Determine whether or not to tick, depending on whether child is longer than parent; 
  // + Set CSS animation properties
  useEffect(() => {
    const parent = tickerTextWrapperRef.current
    const parentWidth = parent.getBoundingClientRect().width
    const childWidth = tickerTextWrapperRef.current.children[0].scrollWidth
    const shouldTick = childWidth + MARGIN_LEFT + 10 > parentWidth // + 10 is to account for distance between play button and text container
    const calcDuration = childWidth / parentWidth * DURATION_CONSTANT  // Calculate duration based on width of text container

    setEndPos(`-${childWidth + MARGIN_LEFT}px`) 
    setDuration(`${calcDuration}s`)
    setTextShouldTick(shouldTick) 
  }, [tickerTextWrapperRef, windowSize.width])

  

  // Apply animation to the ticker text
  useEffect(() => {
    if (textShouldTick) {
      const tickerTextChildren = tickerTextWrapperRef.current.childNodes
      // Re-set the animation if it already exists
      tickerTextChildren.forEach(child => {   
        child.classList.remove("ticker-animation")
        // Triger a reflow to reset CSS animation
        void child.offsetWidth
      })
      
      // Add animation class on a delay
      setTimeout(() => {
        tickerTextChildren.forEach( (child, i) => {
          if (i > 0) child.classList.add('show-clone')
          child.classList.add("ticker-animation")
        })
      }, 150)
    }
  }, [textShouldTick, windowSize.width])

  return (
      <TickerTextWrapperSPAN ref={tickerTextWrapperRef}>
      {
        textShouldTick
          ? [...Array(NUM_CLONES).keys()].map((i) => {
              return (
                React.cloneElement(
                  <TickerTextItemSPAN />, {
                    key: `ticker-child-${i}`,
                    position: endPos,
                    duration
                  }, ...text)
              )
            })  
        :  <TickerTextItemSPAN> {text} </TickerTextItemSPAN>
      }
      </TickerTextWrapperSPAN>
  )
}

Ticker.propTypes = {
  text: PropTypes.string.isRequired 
}

// Memoize the text so that we don't have to re-render the text every time the Ticker's parent component is updated 
export default memo(Ticker)
