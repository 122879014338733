import { css } from "@emotion/react"
import { BREAKPOINTS } from "../../../constants"

/*
  `var(--container-width)`
  To be used whenever you need to constrain an element to grid's maximum width
*/

/*
  `var(--content-width)`
  Used to constrain text columns to a readable width
  * Probably will only need to be used in ContentText component
  * Calculation equals 722px + padding at 1600px browser width
*/

const GridCSS = css`
  :root {
    --container-width: calc(100vw - 60px);
    --content-width: calc((var(--font-size--text-regular) * 34.38) + 60px);
  }

  @media (min-width: ${BREAKPOINTS.M}) {
    :root {
      --container-width: min(calc(100vw - 120px), 1280px);
    }
  }

  @media (min-width: ${BREAKPOINTS.XL}) {
    :root {
      --container-width: min(calc(1280px + (2400 - 1600) * (100vw - 1600px) / (2400 - 1600)), 1600px);
    }
  }
`

export default GridCSS
