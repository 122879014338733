import React from 'react'
import { Global } from '@emotion/react'

import styles from './styles'
import Fonts from './Fonts/Fonts'

const GlobalStyles = () => (
  <>
    <Fonts />
    <Global styles={styles} />
  </>
)

export default GlobalStyles
