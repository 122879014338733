import { css } from '@emotion/react'

const IconsCSS = css`
  .Icon {
    &.IconChevronDown {
      &--dark {
        color: var(--color--graphite-400);
      }
    }
    
  }
`

export default IconsCSS
